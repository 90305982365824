<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <span class="text_1">succour</span>
      <span class="text_2">利用移动互联网技术</span>
      <span class="text_3">让道路救援更简单、更高效</span>
      <span class="text_4">更专业的道路救援系统</span>
      <span class="text_5">Road&nbsp;rescue&nbsp;system</span>
      <div class="text-wrapper_1 flex-col" @click="freeClick">立即咨询&gt;</div>
    </div>
    <div class="box_2 flex-col">
      <div class="group_24 flex-col">
        <div class="box_12 flex-row">
          <div class="text-group_22 flex-col justify-between">
            <span class="text_26">三大核心优势</span>
            <span class="text_27">救援随行，专业可靠</span>
          </div>
        </div>
        <div class="box_13 flex-row justify-between">
          <div class="box_14 flex-col">
            <img
              class="image_4"
              referrerpolicy="no-referrer"
              src="../assets/1.png"
            />
          </div>
          <div class="box_14 flex-col">
            <img
              class="image_4"
              referrerpolicy="no-referrer"
              src="../assets/2.png"
            />
          </div>
          <div class="box_14 flex-col">
            <img
              class="image_4"
              referrerpolicy="no-referrer"
              src="../assets/3.png"
            />
          </div>
        </div>
        <div class="box_17 flex-row justify-between">
          <div class="list_1 flex-row">
            <div
              class="text-wrapper_8 flex-col justify-between"
              v-for="(item, index) in loopData0"
              :key="index"
            >
              <span class="text_28" v-html="item.lanhutext0"></span>
              <span class="text_29" v-html="item.lanhutext1"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="text-group_1 flex-col justify-between">
        <span class="text_7">四大贴心服务</span>
        <span class="text_8">功能丰富，救援无忧</span>
      </div>
      <div class="group_1 flex-row justify-between">
        <div class="section_1 flex-col">
          <div class="box_3 flex-row">
            <div class="group_2 flex-col"></div>
            <span class="text_9">救援服务</span>
            <img
              class="label_1"
              referrerpolicy="no-referrer"
              src="../assets/5.png"
            />
          </div>
          <div class="box_4 flex-row">
            <div class="image-text_1 flex-row justify-between align-center">
              <img
                class="thumbnail_1"
                referrerpolicy="no-referrer"
                src="../assets/6.png"
              />
              <span class="text-group_2">一键呼叫救援</span>
            </div>
          </div>
          <div class="box_4 flex-row">
            <div class="image-text_1 flex-row justify-between align-center">
              <img
                class="thumbnail_1"
                referrerpolicy="no-referrer"
                src="../assets/7.png"
              />
              <span class="text-group_2">救援服务地图</span>
            </div>
          </div>
          <!--  -->
          <div class="box_4 flex-row">
            <div class="image-text_1 flex-row justify-between align-center">
              <img
                class="thumbnail_1"
                referrerpolicy="no-referrer"
                src="../assets/8.png"
              />
              <span class="text-group_2">救援终端一览</span>
            </div>
          </div>
          <div class="box_4 flex-row">
            <div class="image-text_1 flex-row justify-between align-center">
              <img
                class="thumbnail_1"
                referrerpolicy="no-referrer"
                src="../assets/9.png"
              />
              <span class="text-group_2">车辆信息登记</span>
            </div>
          </div>
          <div class="box_4 flex-row">
            <div class="image-text_1 flex-row justify-between align-center">
              <img
                class="thumbnail_1"
                referrerpolicy="no-referrer"
                src="../assets/10.png"
              />
              <span class="text-group_2">救援结果反馈</span>
            </div>
          </div>
          <!--  -->
          <span class="text_10">……</span>
        </div>
        <div class="section_2 flex-col">
          <div class="box_3 flex-row">
            <div class="group_2 flex-col"></div>
            <span class="text_9">管理服务</span>
            <img
              class="label_1"
              referrerpolicy="no-referrer"
              src="../assets/11.png"
            />
          </div>
          <div class="box_4 flex-row">
            <div class="image-text_1 flex-row justify-between align-center">
              <img
                class="thumbnail_1"
                referrerpolicy="no-referrer"
                src="../assets/12.png"
              />
              <span class="text-group_2">终端店铺管理</span>
            </div>
          </div>
          <div class="box_4 flex-row">
            <div class="image-text_1 flex-row justify-between align-center">
              <img
                class="thumbnail_1"
                referrerpolicy="no-referrer"
                src="../assets/13.png"
              />
              <span class="text-group_2">司机用户管理</span>
            </div>
          </div>
          <!--  -->
          <div class="box_4 flex-row">
            <div class="image-text_1 flex-row justify-between align-center">
              <img
                class="thumbnail_1"
                referrerpolicy="no-referrer"
                src="../assets/14.png"
              />
              <span class="text-group_2">代理商管理</span>
            </div>
          </div>
          <div class="box_4 flex-row">
            <div class="image-text_1 flex-row justify-between align-center">
              <img
                class="thumbnail_1"
                referrerpolicy="no-referrer"
                src="../assets/15.png"
              />
              <span class="text-group_2">救援服务管理</span>
            </div>
          </div>
          <div class="box_4 flex-row">
            <div class="image-text_1 flex-row justify-between align-center">
              <img
                class="thumbnail_1"
                referrerpolicy="no-referrer"
                src="../assets/16.png"
              />
              <span class="text-group_2">信息通知管理</span>
            </div>
          </div>
          <!--  -->
          <span class="text_10">……</span>
        </div>
        <div class="section_2 flex-col">
          <div class="box_3 flex-row">
            <div class="group_2 flex-col"></div>
            <span class="text_9">分析服务</span>
            <img
              class="label_1"
              referrerpolicy="no-referrer"
              src="../assets/17.png"
            />
          </div>
          <div class="box_4 flex-row">
            <div class="image-text_1 flex-row justify-between align-center">
              <img
                class="thumbnail_1"
                referrerpolicy="no-referrer"
                src="../assets/18.png"
              />
              <span class="text-group_2">救援服务分析</span>
            </div>
          </div>
          <div class="box_4 flex-row">
            <div class="image-text_1 flex-row justify-between align-center">
              <img
                class="thumbnail_1"
                referrerpolicy="no-referrer"
                src="../assets/19.png"
              />
              <span class="text-group_2">平台用户分析</span>
            </div>
          </div>
          <!--  -->
          <div class="box_4 flex-row">
            <div class="image-text_1 flex-row justify-between align-center">
              <img
                class="thumbnail_1"
                referrerpolicy="no-referrer"
                src="../assets/20.png"
              />
              <span class="text-group_2">推广渠道分析</span>
            </div>
          </div>
          <div class="box_4 flex-row">
            <div class="image-text_1 flex-row justify-between align-center">
              <img
                class="thumbnail_1"
                referrerpolicy="no-referrer"
                src="../assets/21.png"
              />
              <span class="text-group_2">终端店铺分析</span>
            </div>
          </div>
          <div class="box_4 flex-row">
            <div class="image-text_1 flex-row justify-between align-center">
              <img
                class="thumbnail_1"
                referrerpolicy="no-referrer"
                src="../assets/22.png"
              />
              <span class="text-group_2">数据可视化大屏</span>
            </div>
          </div>
          <!--  -->
          <span class="text_10">……</span>
        </div>
        <div class="section_2 flex-col">
          <div class="box_3 flex-row">
            <div class="group_2 flex-col"></div>
            <span class="text_9">推广服务</span>
            <img
              class="label_1"
              referrerpolicy="no-referrer"
              src="../assets/23.png"
            />
          </div>
          <div class="box_4 flex-row">
            <div class="image-text_1 flex-row justify-between align-center">
              <img
                class="thumbnail_1"
                referrerpolicy="no-referrer"
                src="../assets/24.png"
              />
              <span class="text-group_2">合作入驻</span>
            </div>
          </div>
          <div class="box_4 flex-row">
            <div class="image-text_1 flex-row justify-between align-center">
              <img
                class="thumbnail_1"
                referrerpolicy="no-referrer"
                src="../assets/25.png"
              />
              <span class="text-group_2">代理商任务</span>
            </div>
          </div>
          <!--  -->
          <div class="box_4 flex-row">
            <div class="image-text_1 flex-row justify-between align-center">
              <img
                class="thumbnail_1"
                referrerpolicy="no-referrer"
                src="../assets/26.png"
              />
              <span class="text-group_2">推荐终端店铺</span>
            </div>
          </div>
          <div class="box_4 flex-row">
            <div class="image-text_1 flex-row justify-between align-center">
              <img
                class="thumbnail_1"
                referrerpolicy="no-referrer"
                src="../assets/27.png"
              />
              <span class="text-group_2">推荐司机</span>
            </div>
          </div>
          <div class="box_4 flex-row">
            <div class="image-text_1 flex-row justify-between align-center">
              <img
                class="thumbnail_1"
                referrerpolicy="no-referrer"
                src="../assets/28.png"
              />
              <span class="text-group_2">分享海报</span>
            </div>
          </div>
          <!--  -->
          <span class="text_10">……</span>
        </div>
      </div>
      <div class="group_21 flex-row" id="group_1">
        <img
          class="image_1"
          referrerpolicy="no-referrer"
          src="../assets/29.png"
          :class="OneShow ? 'animate__animated animate__slideInLeft' : ''"
        />
        <div
          class="box_10 flex-col"
          :class="OneShow ? 'animate__animated animate__slideInRight' : ''"
        >
          <span class="text_17">道路紧急救援</span>
          <div class="text-wrapper_2 flex-col">
            <span class="text_18">一键呼叫救援</span>
          </div>
          <span class="paragraph_1"
            >一键呼叫救援服务，快速选择救援类型，就近服务快速抵达</span
          >
          <div class="text-wrapper_3 flex-col" @click="freeClick">咨询详情</div>
        </div>
      </div>
      <div class="group_22 flex-row justify-between" id="group_2">
        <div
          class="section_6 flex-col"
          :class="TwoShow ? 'animate__animated animate__slideInLeft' : ''"
        >
          <span class="text_20">救援服务数据可视化</span>
          <div class="text-wrapper_4 flex-col">
            <span class="text_21">可视化救援服务</span>
          </div>
          <span class="paragraph_2"
            >地图可视化救援店铺终端，终端店铺分析，推广渠道分析，救援服务反馈</span
          >
          <div class="text-wrapper_3 flex-col" @click="freeClick">咨询详情</div>
        </div>
        <img
          class="image_2"
          referrerpolicy="no-referrer"
          src="../assets/30.png"
          :class="TwoShow ? 'animate__animated animate__slideInRight' : ''"
        />
      </div>
      <div class="group_23 flex-row" id="group_3">
        <img
          class="image_3"
          referrerpolicy="no-referrer"
          src="../assets/31.png"
          :class="ThreeShow ? 'animate__animated animate__slideInLeft' : ''"
        />
        <div
          class="box_11 flex-col"
          :class="ThreeShow ? 'animate__animated animate__slideInRight' : ''"
        >
          <span class="text_23">平台包含救援服务全部功能</span>
          <div class="text-wrapper_6 flex-col">
            <span class="text_24">多种角色管理</span>
          </div>
          <span class="paragraph_3"
            >店铺终端管理、司机用户管理、平台招商管理，应有尽有，为救援服务保驾护航</span
          >
          <div class="text-wrapper_3 flex-col" @click="freeClick">咨询详情</div>
        </div>
      </div>
    </div>
    <div class="box_18 flex-col">
      <span class="text_32">道路救援系统</span>
      <span class="text_33">服务更可靠&nbsp; 安全更智能</span>
      <div class="text-wrapper_11 flex-col" @click="freeClick">立即咨询</div>
    </div>
    <!--  -->
    <div class="section_32 flex-row">
      <div class="box_47 flex-col">
        <img
          class="image_42"
          referrerpolicy="no-referrer"
          src="../assets/35.png"
        />
        <span class="text_104">河南品讯网络科技有限公司</span>
        <span class="text_105">0379-61118888</span>
        <span class="paragraph_25"
          >地址：河南省洛阳市老城区青年创业大厦24层</span
        >
      </div>
      <div class="box_48 flex-col"></div>
      <div class="text-wrapper_38 flex-col">
        <span class="text_106">产品体系</span>
        <span
          class="text_107"
          @click="goOther('http://www.hnpinxun.cn/yuanchengtakan.html')"
          >远程踏勘管理系统
        </span>
        <span class="text_107" @click="goOther('http://yt.hnpinxun.cn/')"
          >友推云·电商SaaS平台</span
        >
        <span class="text_107" @click="goOther('https://fastrms.hnpinxun.cn/')"
          >视频资源管理AI工具</span
        >
        <span class="text_107" @click="goOther('http://hsxx.hnpinxun.cn/')"
          >亥时学习平台</span
        >
        <span class="text_107" @click="goOther('https://jifen.hnpinxun.cn/')"
          >文明积分银行</span
        >
        <span class="text_107" @click="goOther('https://linli.hnpinxun.cn/')"
          >邻里中心智慧平台</span
        >
      </div>
      <div class="text-wrapper_38 flex-col">
        <span class="text_106"></span>
        <span class="text_107" @click="goOther('https://gcsc.yt.hnpinxun.cn/')"
          >工厂生产管理系统</span
        >
        <span class="text_107" @click="goOther('https://jdgl.yt.hnpinxun.cn/')"
          >酒店系统</span
        >
        <span class="text_107" @click="goOther('https://dysbd.yt.hnpinxun.cn/')"
          >党员双报到</span
        >
        <span class="text_107" @click="goOther('https://hcjy.yt.hnpinxun.cn/')"
          >货车道路救援系统</span
        >
        <span class="text_107" @click="goOther('https://zs.yt.hnpinxun.cn/')"
          >招商宣传</span
        >
        <span class="text_107" @click="goOther('https://hw.yt.hnpinxun.cn/')"
          >智慧环卫综合平台</span
        >
      </div>
      <div class="text-wrapper_38 flex-col">
        <span class="text_106"></span>
        <span class="text_107" @click="goOther('https://jz.yt.hnpinxun.cn/')"
          >家政行业公共服务平台</span
        >
      </div>

      <div class="box_49 flex-col"></div>
      <div class="box_50 flex-col justify-between">
        <img
          class="image_43"
          referrerpolicy="no-referrer"
          src="../assets/33.png"
        />
        <span class="text_120">微信小程序</span>
      </div>
      <div class="box_51 flex-col justify-between">
        <img
          class="image_44"
          referrerpolicy="no-referrer"
          src="../assets/34.png"
        />
        <span class="text_121">微信公众号</span>
      </div>
    </div>
    <div class="text-wrapper_19 flex-col">
      <span class="text_44"
        >Copyright&nbsp;©&nbsp;2024&nbsp;河南品讯网络科技有限公司&nbsp;版权所有豫ICP备18009817号</span
      >
    </div>

    <!--  -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      loopData0: [
        {
          lanhutext0: "一键呼叫",
          lanhutext1: "一键呼叫，就近服务，快速抵达为您保驾护航",
        },
        {
          lanhutext0: "智慧服务",
          lanhutext1: "地图服务，快捷导航，到店服务让您畅行无阻",
        },
        {
          lanhutext0: "价格透明",
          lanhutext1: "在线评价，价格透明，优质服务让您使用放心",
        },
      ],
      constants: {},
      OneShow: true,
      TwoShow: false,
      ThreeShow: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  BeforeUnloadEvent() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    freeClick() {
      window.open("http://www.hnpinxun.cn/#/", "_blank");
    },
    goOther(e) {
      window.open(e, "_blank");
    },
    handleScroll() {
      const scrollPosition = window.scrollY || window.pageYOffset;
      var group1Top = document.getElementById("group_1").offsetTop;
      const isGroupVisible =
        scrollPosition >= group1Top - window.innerHeight &&
        scrollPosition < group1Top;

      if (isGroupVisible && !this.OneShow) {
        this.OneShow = true;
      } else if (scrollPosition === 0) {
        this.OneShow = false;
      }
      //
      var group2Top = document.getElementById("group_2").offsetTop;
      const isGroupVisible2 =
        scrollPosition >= group2Top - window.innerHeight &&
        scrollPosition < group2Top;

      if (isGroupVisible2 && !this.TwoShow) {
        this.TwoShow = true;
      } else if (scrollPosition === 0) {
        this.TwoShow = false;
      }

      //
      var group3Top = document.getElementById("group_3").offsetTop;
      const isGroupVisible3 =
        scrollPosition >= group3Top - window.innerHeight &&
        scrollPosition < group3Top;

      if (isGroupVisible3 && !this.ThreeShow) {
        this.ThreeShow = true;
      } else if (scrollPosition === 0) {
        this.ThreeShow = false;
      }
    },
  },
};
</script>

<style scoped>
.page {
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  overflow: hidden;
}

.box_1 {
  width: 100vw;
  height: 31.25vw;
  background: url("/src/assets/4.png") 100% no-repeat;
  background-size: 100% 100%;
  justify-content: flex-center;
}

.text_1 {
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.4) 0,
    rgba(255, 255, 255, 0) 100%
  );
  width: 20.84vw;
  height: 4.69vw;
  overflow-wrap: break-word;
  font-size: 4.68vw;
  font-family: Arimo Hebrew Subset Italic-Bold Italic;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  line-height: 5.21vw;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 7.29vw 0 0 18.75vw;
}

.text_2 {
  width: 15vw;
  height: 1.67vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 1.66vw;
  font-family: PingFang SC-Semibold;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  margin: -1.56vw 0 0 18.75vw;
}

.text_3 {
  width: 27.5vw;
  height: 2.3vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 2.29vw;
  font-family: PingFang SC-Semibold;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  margin: 1.04vw 0 0 18.75vw;
}

.text_4 {
  width: 12.5vw;
  height: 1.25vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 1.25vw;
  font-family: PingFang SC-Regular;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  line-height: 5.21vw;
  margin: 1.56vw 0 0 18.75vw;
}

.text_5 {
  width: 8.13vw;
  height: 0.84vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: PingFang SC-Semibold;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  line-height: 5.21vw;
  margin: 0.62vw 0 0 18.75vw;
}

.text-wrapper_1 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 30px;
  height: 2.61vw;
  width: 8.34vw;
  margin: 4vw 0 7.39vw 18.75vw;
  cursor: pointer;
  color: rgba(24, 48, 144, 1);
  font-size: 1.04vw;
  font-family: PingFang SC-Semibold;
  font-weight: 600;
  text-align: center;
  line-height: 2.61vw;
}
.text-wrapper_1:hover {
  background: rgba(24, 48, 144, 1);
  color: #fff;
}

.box_2 {
  position: relative;
  width: 100vw;
  height: 165.31vw;
  margin-bottom: 0.06vw;
}

.text-group_1 {
  width: 18.75vw;
  height: 5.84vw;
  margin: 4vw 0 0 40.62vw;
}

.text_7 {
  width: 18.75vw;
  height: 3.13vw;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 3.12vw;
  font-family: MiSans-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 5.21vw;
}

.text_8 {
  width: 15vw;
  height: 1.67vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 1.66vw;
  font-family: MiSans-Regular;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  line-height: 5.21vw;
  margin: 1.04vw 0 0 1.87vw;
}

.group_1 {
  width: 62.5vw;
  height: 21.88vw;
  margin: 3.12vw 0 0 18.75vw;
}

.section_1 {
  box-shadow: 0px 2px 14px 0px rgba(193, 218, 241, 0.6);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  width: 14.85vw;
  height: 21.88vw;
}
.section_1:hover {
  transform: translateY(-10px);
}
.box_3 {
  background-image: linear-gradient(
    91deg,
    rgba(236, 65, 69, 1) 0,
    rgba(249, 143, 81, 1) 100%
  );
  border-radius: 10px 10px 0px 0px;
  width: 14.85vw;
  height: 3.13vw;
}

.group_2 {
  background-color: rgba(255, 255, 255, 1);
  width: 0.21vw;
  height: 0.94vw;
  margin: 1.09vw 0 0 1.04vw;
}

.text_9 {
  width: 3.75vw;
  height: 0.94vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.93vw;
  font-family: PingFang SC-Semibold;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
  margin: 0.9vw 0 0 0.57vw;
}

.label_1 {
  width: 1.88vw;
  height: 1.88vw;
  margin: 0.62vw 0.52vw 0 6.87vw;
}

.box_4 {
  background-color: rgba(255, 252, 249, 1);
  border-radius: 5px;
  width: 13.29vw;
  height: 2.61vw;
  margin: 0.78vw 0 0 0.78vw;
}

.image-text_1 {
  width: 5.63vw;
  height: 0.73vw;
  margin: 0.93vw 0 0 3.12vw;
}

.thumbnail_1 {
  width: 0.73vw;
  height: 0.73vw;
}

.text-group_2 {
  width: 4.38vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 0.72vw;
  font-family: PingFang SC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
}

.text_10 {
  width: 1.46vw;
  height: 0.73vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 0.72vw;
  font-family: PingFang SC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  margin: 0.3vw 0 0.57vw 6.71vw;
}

.section_2 {
  box-shadow: 0px 2px 14px 0px rgba(193, 218, 241, 0.6);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  width: 14.85vw;
  height: 21.88vw;
  margin-left: 1.05vw;
}
.section_2:hover {
  transform: translateY(-10px);
}

.group_21 {
  background-color: rgba(255, 251, 247, 1);
  width: 100vw;
  height: 30.21vw;
  margin-top: 4.59vw;
}

.image_1 {
  width: 20.84vw;
  height: 26.57vw;
  margin: 3.64vw 0 0 22.08vw;
}

.box_10 {
  width: 23.03vw;
  height: 17.92vw;
  margin: 5.72vw 23.85vw 0 10.2vw;
}

.text_17 {
  width: 10vw;
  height: 1.67vw;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 1.66vw;
  font-family: MiSans-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  margin-left: 0.53vw;
}

.text-wrapper_2 {
  background-color: rgba(255, 244, 237, 1);
  border-radius: 5px;
  height: 3.13vw;
  border: 2px solid rgba(235, 46, 59, 1);
  margin-top: 1.05vw;
  width: 13.55vw;
}

.text_18 {
  height: 2.09vw;
  overflow-wrap: break-word;
  color: rgba(235, 46, 59, 1);
  font-size: 2.08vw;
  font-family: MiSans-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
}

.paragraph_1 {
  width: 22.5vw;
  height: 3.75vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 1.25vw;
  font-family: MiSans-Regular;
  font-weight: normal;
  text-align: left;
  line-height: 2vw;
  margin: 1.5vw 0 0 0.52vw;
}

.text-wrapper_3 {
  background-color: rgba(235, 46, 59, 1);
  border-radius: 10px;
  height: 2.61vw;
  width: 8.34vw;
  margin: 4.5vw 0 0 0.52vw;
  color: rgba(255, 255, 255, 1);
  font-size: 0.93vw;
  font-family: MiSans-Regular;
  text-align: center;
  line-height: 2.61vw;
  cursor: pointer;
}

.text-wrapper_3:hover {
  transform: scale(1.1);
}

.group_22 {
  width: 55.21vw;
  height: 26.57vw;
  margin: 3.64vw 0 0 22.91vw;
}

.section_6 {
  width: 24.28vw;
  height: 17.92vw;
  margin-top: 2.09vw;
}

.text_20 {
  width: 15vw;
  height: 1.67vw;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 1.66vw;
  font-family: MiSans-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  margin-left: 0.53vw;
}

.text-wrapper_4 {
  background-color: rgba(255, 244, 237, 1);
  border-radius: 5px;
  height: 3.13vw;
  border: 2px solid rgba(235, 46, 59, 1);
  margin-top: 1.05vw;
  width: 15.63vw;
}

.text_21 {
  height: 2.09vw;
  overflow-wrap: break-word;
  color: rgba(235, 46, 59, 1);
  font-size: 2.08vw;
  font-family: MiSans-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  margin: 0vw 0 0 0.52vw;
}

.paragraph_2 {
  width: 23.75vw;
  height: 3.75vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 1.25vw;
  font-family: MiSans-Regular;
  font-weight: normal;
  text-align: left;
  line-height: 2vw;
  margin: 1.5vw 0 0 0.52vw;
}

.image_2 {
  width: 20.84vw;
  height: 26.57vw;
}

.group_23 {
  background-color: rgba(255, 251, 247, 1);
  width: 100vw;
  height: 30.21vw;
  margin-bottom: 15.68vw;
}

.image_3 {
  width: 20.84vw;
  height: 26.57vw;
  margin: 3.64vw 0 0 21.87vw;
}

.box_11 {
  width: 26.78vw;
  height: 17.92vw;
  margin: 5.72vw 20.1vw 0 10.41vw;
}

.text_23 {
  width: 20vw;
  height: 1.67vw;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 1.66vw;
  font-family: MiSans-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  margin-left: 0.53vw;
}

.text-wrapper_6 {
  background-color: rgba(255, 244, 237, 1);
  border-radius: 5px;
  height: 3.13vw;
  border: 2px solid rgba(235, 46, 59, 1);
  margin-top: 1.05vw;
  width: 13.55vw;
}

.text_24 {
  width: 12.5vw;
  height: 2.09vw;
  overflow-wrap: break-word;
  color: rgba(235, 46, 59, 1);
  font-size: 2.08vw;
  font-family: MiSans-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  margin: 0vw 0 0 0.52vw;
}

.paragraph_3 {
  width: 26.25vw;
  height: 3.75vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 1.25vw;
  font-family: MiSans-Regular;
  font-weight: normal;
  text-align: left;
  line-height: 2vw;
  margin: 1.56vw 0 0 0.52vw;
}

.text-wrapper_7 {
  background-color: rgba(235, 46, 59, 1);
  border-radius: 10px;
  height: 2.61vw;
  width: 8.34vw;
  margin: 4.16vw 0 0 0.52vw;
}

.group_24 {
  background-color: rgba(255, 251, 247, 1);
  height: 35.42vw;
  width: 100vw;
  justify-content: flex-center;
}

.box_12 {
  width: 18.75vw;
  height: 5.84vw;
  margin: 4.16vw 0 0 40.57vw;
}

.text-group_22 {
  width: 18.75vw;
  height: 5.84vw;
}

.text_26 {
  width: 18.75vw;
  height: 3.13vw;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 3.12vw;
  font-family: MiSans, MiSans;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 5.21vw;
}

.text_27 {
  width: 15vw;
  height: 1.67vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 1.66vw;
  font-family: MiSans-Regular;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  line-height: 5.21vw;
  margin: 1.04vw 0 0 1.87vw;
}

.box_13 {
  width: 53.13vw;
  height: 8.34vw;
  margin: 3.54vw 0 0 23.43vw;
}

.box_14 {
  border-radius: 50%;
  height: 8.34vw;
  width: 8.34vw;
  margin: 0.78vw 0 0 0.78vw;
}
.box_14:hover {
  transform: scale(1.1);
}

.image_4 {
  width: 100%;
  height: 100%;
}

.box_17 {
  width: 62.3vw;
  height: 7.3vw;
  margin: 0vw 0 4.16vw 18.85vw;
}

.list_1 {
  width: 39.9vw;
  height: 6.78vw;
  justify-content: space-between;
}

.text-wrapper_8 {
  width: 17.5vw;
  height: 6.78vw;
  margin-right: 4.9vw;
}

.text_28 {
  width: 6.67vw;
  height: 1.67vw;
  overflow-wrap: break-word;
  color: rgba(235, 46, 59, 1);
  font-size: 1.66vw;
  font-family: MiSans-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 5.21vw;
  margin-left: 5.42vw;
}

.text_29 {
  width: 17.5vw;
  height: 3.55vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 1.25vw;
  font-family: MiSans-Regular;
  font-weight: normal;
  text-align: center;
  line-height: 2.5vw;
  margin-top: 3.13vw;
}
.box_18 {
  object-fit: cover;
  width: 100vw;
  height: 16.67vw;
  background: url("/src/assets/32.png") 100% no-repeat;
  background-size: 100% 100%;
}

.text_32 {
  width: 12.5vw;
  height: 2.09vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 2.08vw;
  font-family: MiSans-Regular;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  margin: 3.12vw 0 0 43.75vw;
}

.text_33 {
  width: 32.4vw;
  height: 3.13vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 3.12vw;
  font-family: MiSans-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  margin: 1.56vw 0 0 33.8vw;
}

.text-wrapper_11 {
  border-radius: 10px;
  height: 3.13vw;
  border: 2px solid rgba(255, 255, 255, 1);
  width: 9.38vw;
  margin: 1.56vw 0 2.08vw 45.31vw;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  font-size: 1.25vw;
  font-family: MiSans-Regular;
  line-height: 3vw;
  cursor: pointer;
}

.text-wrapper_11:hover {
  transform: scale(1.1);
}
/*  */
/*  */

.section_32 {
  background-color: rgba(34, 34, 34, 1);
  width: 100%;
  height: 17.63vw;
  justify-content: flex-center;
}

.box_47 {
  width: 14.12vw;
  height: 10.73vw;
  margin: 3.02vw 0 0 18.7vw;
}

.image_42 {
  width: 2.87vw;
  height: 2.87vw;
  margin-left: 5.58vw;
}

.text_104 {
  width: 13.96vw;
  height: 1.1vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 1.04vw;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 1.05vw;
  margin-top: 0.73vw;
}

.text_105 {
  width: 14.02vw;
  height: 1.25vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 1.56vw;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 1.57vw;
  margin-top: 0.94vw;
}

.paragraph_25 {
  width: 14.07vw;
  height: 1.88vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  line-height: 1.25vw;
  margin: 1.97vw 0 0 0.05vw;
}

.box_48 {
  background-color: rgba(255, 255, 255, 0.12);
  width: 0.06vw;
  height: 10.42vw;
  margin: 3.28vw 0 0 3.26vw;
}

.text-wrapper_38 {
  width: 5.68vw;
  height: 9.38vw;
  margin: 1.9vw 0 0 5.36vw;
}

.text_106 {
  width: 3.7vw;
  height: 0.84vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
}

.text_107 {
  width: 4.8vw;
  height: 0.84vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.4vw 0 0 0.05vw;
  cursor: pointer;
}
.text_107:hover {
  color: #0b74ef;
}

.text-wrapper_39 {
  width: 3.7vw;
  height: 9.43vw;
  margin: 3.8vw 0 0 5.31vw;
}

.text_111 {
  width: 3.7vw;
  height: 0.89vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
}

.text_112 {
  width: 2.66vw;
  height: 0.79vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.71vw 0 0 0.05vw;
}

.text_113 {
  width: 3.55vw;
  height: 0.84vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.19vw 0 0 0.05vw;
}

.text_114 {
  width: 3.6vw;
  height: 0.79vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.19vw 0 0 0.05vw;
}

.box_49 {
  background-color: rgba(255, 255, 255, 0.12);
  width: 0.06vw;
  height: 10.42vw;
  margin: 3.22vw 0 0 5.41vw;
}

.box_50 {
  width: 6.78vw;
  height: 8.91vw;
  margin: 4.2vw 0 0 4.3vw;
}

.image_43 {
  width: 6.78vw;
  height: 6.78vw;
}

.text_120 {
  width: 4.48vw;
  height: 0.79vw;
  overflow-wrap: break-word;
  color: rgba(223, 223, 223, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.35vw 0 0 1.14vw;
}

.box_51 {
  width: 6.78vw;
  height: 8.91vw;
  margin: 4.2vw 12.55vw 0 1.25vw;
}

.image_44 {
  width: 6.78vw;
  height: 6.78vw;
}

.text_121 {
  width: 4.54vw;
  height: 0.79vw;
  overflow-wrap: break-word;
  color: rgba(223, 223, 223, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.35vw 0 0 1.14vw;
}
.text-wrapper_19 {
  background-color: rgba(59, 59, 59, 1);
  height: 3.49vw;
  margin-top: -0.05vw;
}

.text_44 {
  width: 28.6vw;
  height: 0.89vw;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.35vw 0 0 35.67vw;
}
</style>
